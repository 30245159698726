export default {
    host : 'https://projets3.hsolenne.fr/wp-json/wp/v2',

    auth : 'https://projets3.hsolenne.fr/wp-json/jwt-auth/v1/',
    user: 'ax3l9_hsolenne',
    psw: 'solphysicAL',

    titre : "Connexion",
}

